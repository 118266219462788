import React, { Component } from 'react';
import cx from 'classnames';
import { KEY_CODES } from 'constants/app.constant';
import { DependentOption, MenuOption } from 'models';
import { ELSIcon, ELSCheckBox } from 'components/common';
import { onKeyDownHandler, getIndexByKeyCode } from 'helpers/ui.helper';

type CheckedKeys = string[];

// DependentOption is a list of options which have dependent options.
// Only the parent option will be displayed, all dependent options will be hidden.
// Dependent options will follow the checked state of the parent option.
interface CheckboxMenuProps {
  checkedKeys: CheckedKeys;
  options: MenuOption[];
  inline?: boolean;
  onOptionChange?: Function;
  customLabelRender?: Function;
  dependentOption?: Array<DependentOption>;
  allOptionDisplayTitle?: string;
  customMultiSelectedLabel?: string;
}

interface CheckboxMenuState {
  opened: boolean;
  optionIdxSelectedByKey: number;
  shouldChangeOptionListPosition: boolean;
  displayLabel: string;
  tempCheckedKeys: CheckedKeys;
}

const initialOptionIdx = -1;
const checkAllKey = 'CHECK_ALL_KEY';
const DEFAULT_ALL_OPTION_DISPLAY_TILE = 'All';

class CheckboxMenu extends Component<CheckboxMenuProps, CheckboxMenuState> {
  private displayLabelRef = React.createRef<HTMLDivElement>();
  private allOptions: MenuOption[];
  private allOptionDisplayTitle: string = DEFAULT_ALL_OPTION_DISPLAY_TILE;

  constructor(props) {
    super(props);
    const tempCheckedKeys = this.checkOptionAllForTempCheckKey();
    this.allOptionDisplayTitle = props.allOptionDisplayTitle || DEFAULT_ALL_OPTION_DISPLAY_TILE;
    this.state = {
      opened: false,
      optionIdxSelectedByKey: initialOptionIdx,
      shouldChangeOptionListPosition: false,
      tempCheckedKeys,
      displayLabel: this.getDisplayLabel(tempCheckedKeys)
    };
    this.allOptions = [{ key: checkAllKey, name: 'Select All' }, ...this.props.options];
  }

  componentDidMount() {
    window.addEventListener('resize', this.changeOptionListPosition);
  }

  componentDidUpdate(prevProps, prevState) {
    this.allOptions = [{ key: checkAllKey, name: 'Select All' }, ...this.props.options];
    if (this.state.opened && prevState.opened !== this.state.opened) {
      this.changeOptionListPosition();
    }
    if (this.props.customLabelRender && this.props.customLabelRender() !== this.state.displayLabel) {
      this.changeDisplayLabel();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.changeOptionListPosition);
  }

  checkOptionAllForTempCheckKey = () => {
    const tempCheckedKeys = this.props.checkedKeys;
    if (tempCheckedKeys.length === this.props.options.length) {
      return [checkAllKey, ...tempCheckedKeys];
    }
    return tempCheckedKeys;
  };

  changeOptionListPosition = (): void => {
    const optionBoxBoundingClientRect = this.displayLabelRef.current.getBoundingClientRect();
    if (optionBoxBoundingClientRect.x > window.innerWidth / 2) {
      this.setState({ shouldChangeOptionListPosition: true });
    } else {
      this.setState({ shouldChangeOptionListPosition: false });
    }
  };

  changeDisplayLabel = (): void => {
    this.setState({ displayLabel: this.props.customLabelRender() });
  };

  getCheckedKeysWhenClickNotAllOption = (checkedKeys: CheckedKeys, option: MenuOption): CheckedKeys => {
    let newChecked;
    const optionKey = option.key;
    const excludeOption = this.props.dependentOption?.find(o => o.parentOption === optionKey)?.dependentOptionlist;
    if (checkedKeys.includes(optionKey)) {
      newChecked = checkedKeys.filter(key => key !== optionKey && !excludeOption?.includes(key));
    } else {
      newChecked = [...checkedKeys, optionKey].concat(excludeOption).filter(o => o);
    }
    return this.getCheckedKeysIntegrateWithCheckAllCondition(newChecked);
  };

  getCheckedKeysIntegrateWithCheckAllCondition = (checkedKeys: CheckedKeys): CheckedKeys => {
    if (checkedKeys.includes(checkAllKey) && checkedKeys.length <= this.props.options.length) {
      return checkedKeys.filter(key => key !== checkAllKey);
    }
    if (checkedKeys.length === this.props.options.length) {
      return [checkAllKey, ...checkedKeys];
    }
    return checkedKeys;
  };

  getCheckedKeysWhenClickAllOption = (checkedKeys: CheckedKeys): CheckedKeys => {
    if (checkedKeys.includes(checkAllKey)) {
      return [];
    }
    return [checkAllKey, ...this.props.options.map(opt => opt.key)];
  };

  getDisplayLabel = (checkedKeys: CheckedKeys): string => {
    if (this.props.customLabelRender) {
      return this.props.customLabelRender();
    }
    if (checkedKeys.includes(checkAllKey) || (!checkedKeys.length && this.props.customMultiSelectedLabel)) {
      return this.allOptionDisplayTitle;
    }
    if (this.props.customMultiSelectedLabel && checkedKeys.length > 1) {
      return this.props.customMultiSelectedLabel;
    }
    const selectedOptions = this.props.options.filter(option => checkedKeys.includes(option.key));
    return selectedOptions.map(option => option.name).join(', ');
  };

  handleOptionChange = (option: MenuOption): void => {
    if (option.key === checkAllKey) {
      this.setState(prevState => ({
        tempCheckedKeys: this.getCheckedKeysWhenClickAllOption(prevState.tempCheckedKeys)
      }));
      return;
    }

    this.setState(prevState => ({
      tempCheckedKeys: this.getCheckedKeysWhenClickNotAllOption(prevState.tempCheckedKeys, option)
    }));
  };

  handleMenuBlur = (): void => {
    this.updateData();
    this.hideOptions();
  };

  handleMenuClick = (): void => {
    this.toggleOptions();
    this.updateData();
  };

  hideOptions = (): void => {
    this.setState({
      opened: false,
      optionIdxSelectedByKey: initialOptionIdx
    });
  };

  // SPACE: Choose option
  // ENTER: Apply the option
  handleMenuKeyDown = (evt): void => {
    const key = evt.keyCode;
    if (key !== KEY_CODES.TAB) {
      evt.preventDefault();
    }
    const max = this.props.options.length;

    switch (key) {
      case KEY_CODES.ENTER: {
        if (this.state.opened) {
          this.updateData();
          this.hideOptions();
        } else {
          this.openOptionByKeyboard();
        }
        break;
      }
      case KEY_CODES.SPACE: {
        if (this.state.opened) {
          const selectedIdx = this.state.optionIdxSelectedByKey;
          if (selectedIdx !== initialOptionIdx) {
            this.handleOptionChange(this.allOptions[selectedIdx]);
          }
        } else {
          this.openOptionByKeyboard();
        }
        break;
      }
      case KEY_CODES.UP:
      case KEY_CODES.TAB:
      case KEY_CODES.DOWN: {
        this.setState(prevState => ({
          optionIdxSelectedByKey: getIndexByKeyCode(key, prevState.optionIdxSelectedByKey, max)
        }));
        break;
      }
      case KEY_CODES.ESC: {
        this.hideOptions();
        this.setState({
          tempCheckedKeys: this.checkOptionAllForTempCheckKey()
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  toggleOptions = (): void => {
    this.setState(prevState => ({
      opened: !prevState.opened,
      optionIdxSelectedByKey: initialOptionIdx
    }));
  };

  openOptionByKeyboard = (): void => {
    this.setState({
      opened: true,
      optionIdxSelectedByKey: 0
    });
  };

  updateData = (): void => {
    const { tempCheckedKeys } = this.state;
    let checkedKeys;
    if (tempCheckedKeys.length) {
      checkedKeys = tempCheckedKeys.filter(key => key !== checkAllKey);
    } else {
      checkedKeys = this.props.options.map(option => option.key);
      this.setState({ tempCheckedKeys: [...checkedKeys, checkAllKey] });
    }
    this.props.onOptionChange(checkedKeys);
    this.setState(prevState => ({ displayLabel: this.getDisplayLabel(prevState.tempCheckedKeys) }));
  };

  isDependentOptionHidden = (excludeOptions: string[], key: string): boolean => {
    const isHide = excludeOptions?.includes(key);
    const dependentOption = this.props.dependentOption?.find(option => option.dependentOptionlist.includes(key));
    const hasParentDependentOption = this.allOptions.findIndex(option => option.key === dependentOption?.parentOption) > -1;
    return isHide && hasParentDependentOption;
  };

  render() {
    const { displayLabel, opened, shouldChangeOptionListPosition, optionIdxSelectedByKey, tempCheckedKeys } = this.state;
    const { inline, dependentOption } = this.props;
    const excludeOptions = dependentOption?.flatMap(o => o.dependentOptionlist);
    return (
      <div
        role="button"
        tabIndex={0}
        ref={this.displayLabelRef}
        onBlur={this.handleMenuBlur}
        onClick={this.handleMenuClick}
        onKeyDown={this.handleMenuKeyDown}
        className={cx({ 'u-els-display-inline-block': inline }, 'c-select-menu c-checkbox-menu')}
      >
        <div className={cx({ 'u-els-display-inline-block': inline, 'c-select-menu--opened': opened }, 'c-select-menu__trigger')}>
          <span title={displayLabel} className="c-select-menu__display-label">
            {displayLabel}
          </span>
          <ELSIcon name="chevron-down" size="1x" customClass="u-els-margin-left-1o2 c-select-menu__rotate-arrow" />
        </div>
        {opened && (
          <div className={cx('c-select-menu__options', { 'c-select-menu__options--position-changed': shouldChangeOptionListPosition })}>
            <div className="c-select-menu__options-wrapper">
              <ul className="c-select-menu__options-list">
                {this.allOptions.map((option, idx) => {
                  const changeHandler = evt => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    this.handleOptionChange(option);
                  };
                  const { name, key } = option;
                  return (
                    <li
                      role="option"
                      aria-selected
                      title={name}
                      key={key}
                      className={cx('c-select-menu__option', {
                        'c-select-menu__option--selected-by-key': optionIdxSelectedByKey === idx,
                        'c-select-menu__option--select-all': key === checkAllKey,
                        'c-select-menu__option--hide': this.isDependentOptionHidden(excludeOptions, key)
                      })}
                      onClick={changeHandler}
                      onKeyDown={evt => onKeyDownHandler(evt, changeHandler)}
                    >
                      <ELSCheckBox id={key} name={name} value={key} checked={tempCheckedKeys.includes(key)}>
                        {name}
                      </ELSCheckBox>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CheckboxMenu;
