import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { AppConstant, RoutePath } from 'constants/app.constant';
import { cwReducer } from 'reports/cw/redux/ducks/cw';
import { courseActions, coursesReducer } from 'reports/cw/redux/ducks/courses';
import { ehrActions, ehrReducer } from 'reports/ehr/redux/ducks/ehr';
import { hadActions, hadReducer } from 'reports/had/redux/ducks/had';
import { shActions, shReducer } from 'reports/sh/redux/ducks/sh';
import { SH_PATHS } from 'reports/sh/constants/sh.constant';
import { psActions, psReducer } from 'reports/ps/redux/ducks/ps';
import { hesiNgActions, hesiNgReducer } from 'reports/hesi-ng/redux/ducks/hesi-ng';
import { appActions, appReducer } from './ducks/app';
import { routeReducer } from './ducks/route';
import { languageReducer } from './ducks/language';
import { filterActions, filterReducer } from './ducks/filter';
import { addUpdateData, getData } from '../indexedDB/db';

export const handleDataRestore = async store => {
  return new Promise<void>((resolve: () => void) => {
    (async () => {
      try {
        const actionState = await getData();
        if (!actionState?.value) {
          // No record found in indexedDB
          resolve();
          return;
        }

        const rootStateFromStorage = actionState.value;
        const appState = rootStateFromStorage[AppConstant.reduxResources.APP_STATE];
        const hadState = rootStateFromStorage[AppConstant.reduxResources.HAD_STATE];
        const ehrState = rootStateFromStorage[AppConstant.reduxResources.EHR_STATE];
        const courseState = rootStateFromStorage[AppConstant.reduxResources.COURSES_STATE];
        const filterState = rootStateFromStorage[AppConstant.reduxResources.FILTER_STATE];
        const shState = rootStateFromStorage[AppConstant.reduxResources.SH_STATE];
        const psState = rootStateFromStorage[AppConstant.reduxResources.PS_STATE];
        const hesiNGState = rootStateFromStorage[AppConstant.reduxResources.HESI_NG_STATE];

        if (appState) {
          await store.dispatch(appActions.restoreState(appState));
          await store.dispatch(hadActions.restoreState(hadState));
          await store.dispatch(ehrActions.restoreState(ehrState));
          await store.dispatch(courseActions.restoreState(courseState));
          await store.dispatch(filterActions.restoreState(filterState));
          await store.dispatch(shActions.restoreState(shState));
          await store.dispatch(psActions.restoreState(psState));
          await store.dispatch(hesiNgActions.restoreState(hesiNGState));
        }
        resolve();
      } catch (error) {
        // Resolve the promise even if there is an error to prevent the app from blocking the UI
        resolve();
      }
    })();
  });
};

const createDefaultReducers = history =>
  combineReducers({
    ...routeReducer,
    [AppConstant.reduxResources.ROUTER]: connectRouter(history),
    [AppConstant.reduxResources.APP_STATE]: appReducer,
    [AppConstant.reduxResources.FILTER_STATE]: filterReducer,
    [AppConstant.reduxResources.CW_STATE]: cwReducer,
    [AppConstant.reduxResources.COURSES_STATE]: coursesReducer,
    [AppConstant.reduxResources.LANGUAGE_STATE]: languageReducer,
    [AppConstant.reduxResources.HAD_STATE]: hadReducer,
    [AppConstant.reduxResources.EHR_STATE]: ehrReducer,
    [AppConstant.reduxResources.SH_STATE]: shReducer,
    [AppConstant.reduxResources.PS_STATE]: psReducer,
    [AppConstant.reduxResources.HESI_NG_STATE]: hesiNgReducer
  });

const actionTypesWhitelist = [
  '@@router/LOCATION_CHANGE',
  'APP/SET_EVOLVE_USER_LOADING',
  'APP/SET_SELECTED_COURSE',
  'HESI/SET_PROGRAM_ID',
  'FILTER/RESET_STATE',
  'FILTER/SET_HAD_COHORT_STUDENT_TABLE_ROWS_STATE',
  'FILTER/SET_HAD_COHORT_STUDENT_TABLE_SEARCH',
  'HAD/SET_CATEGORY_GROUP_SELECTED_KEY',
  'SH/SET_STUDENT_AVERAGE_PERFORMANCE_TYPE',
  'PS/SET_SELECTED_EXAM_MIXED_ID',
  'PS/SET_SELECTED_PROGRAM_ID',
  'APP/SET_IS_ALL_AA_DATA_COLLECTED'
];

const addDataToIndexedDB = async (stateStr: string) => {
  const data = { value: stateStr };
  addUpdateData(data).catch(error => {
    // eslint-disable-next-line no-console
    console.warn(error);
  });
};

const storageMiddleware = ({ getState }) => next => action => {
  const result = next(action);
  const allState = getState();
  const userId = allState?.appState?.userId ?? allState?.appState?.loggedInUser;
  if (actionTypesWhitelist.includes(action.type) && userId) {
    try {
      addDataToIndexedDB(allState);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  return result;
};

// Function to get the list of actions required to collect AA data based on the current route
export const getActionsToCollectAAData = () => {
  const actionsToCollectAAData = ['APP/SET_USER_ID', 'APP/SET_USER_ROLE'];
  const locationsHash = window.location.hash.toLowerCase();

  if (locationsHash === `#${SH_PATHS.dashboard.toLowerCase()}`) {
    actionsToCollectAAData.push('SH/SET_INSTRUCTOR_ID');
  } else if (locationsHash === `#${RoutePath.had.toLowerCase()}`) {
    actionsToCollectAAData.push('HAD/SET_PROGRAM_ID', 'HAD/SET_PROGRAM_NAME', 'APP/SET_LOGGED_IN_USER');
  } else {
    actionsToCollectAAData.push('APP/SET_EVOLVE_USER', 'COURSE/SET_COURSE_SECTION_INFO', 'APP/SET_EVOLVE_USER_LOADING', 'APP/SET_FEATURE_FLAGS', 'APP/SET_LOGGED_IN_USER');
  }
  return actionsToCollectAAData;
};

// Middleware to track dispatched actions and update state when all required actions are dispatched
export const allActionsDispatchedMiddleware = store => {
  const allDispatchedActions = [];

  return next => action => {
    // Prevent middleware from running if the action is to set isAllAADataCollected
    if (action.type === 'APP/SET_IS_ALL_AA_DATA_COLLECTED') {
      return next(action);
    }

    // Add action to allDispatchedActions if not already included
    if (!allDispatchedActions.includes(action.type)) {
      allDispatchedActions.push(action.type);
    }

    const actionsToCollectAAData = getActionsToCollectAAData();
    // Check if all required actions have been dispatched
    const isAllDataCollected = actionsToCollectAAData.every(actionType => allDispatchedActions.includes(actionType));

    // Update state if all data is collected and state does not reflect this
    if (isAllDataCollected) {
      const isAllAADataCollected = store?.getState()?.app?.isAllAADataCollected;
      if (!isAllAADataCollected) {
        store.dispatch(appActions.setIsAllAADataCollected(true));
      }
    }

    return next(action);
  };
};

const configureStore = history => {
  const middlewares = [thunkMiddleware, routerMiddleware(history), storageMiddleware, allActionsDispatchedMiddleware];

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(createLogger({ collapsed: true }));
  }

  const enhancers = [applyMiddleware(...middlewares)];
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return createStore(createDefaultReducers(history), {}, composeEnhancers(...enhancers));
};

export { configureStore, addDataToIndexedDB, AppConstant };
