import React, { Component, ReactElement, ReactNode } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { ELSPageLoader } from '@els/els-ui-common-react';
import { ELSIcon } from 'components/common';

import { AppLinkOutPostBody } from 'models/app-linking';
import { appSelectors, appActions } from 'redux/ducks/app';
import { checkPathMatch, isBreadcrumbBackTitleShown } from 'helpers/app.helper';
import { courseActions } from 'reports/cw/redux/ducks/courses';
import { FeatureFlag, WindowMode } from 'models';
import { FeatureFlagNames, ANALYTIC_FEATURES, CS_APP_TITLE } from 'constants/app.constant';
import { HAD_PATHS, MASK_DATA_SESSION_KEY } from 'reports/had/constants/had.constant';
import { hadSelectors } from 'reports/had/redux/ducks/had';
import { isCSAppByAppName, isEABAppByAppName } from 'services/app-linking.service';
import { isFeatureEnabled } from 'services/feature-flag.service';
import { onKeyDownHandler } from 'helpers/ui.helper';
import { Program, CourseSectionInfo } from 'reports/had/models';
import { PS_PATHS } from 'reports/ps/constants';
import { shSelectors } from 'reports/sh/redux/ducks/sh';
import { trackAAFeature } from 'services/adobe-analytics.service';
import withAppLinkingBackToPreviousApp from 'components/common/with-app-linking-back-to-previous-app/withAppLinkingBackToPreviousApp';
import withResizeScreen from '../with-resize-screen/withResizeScreen';
import PageNavigator from '../page-navigator/PageNavigator';
import BentoBoxModal, { BentoBoxModalProps } from '../bento-box-modal/BentoBoxModal';

require('@els/els-styleguide-core/images/NonSolusLogo.svg');
require('assets/common/images/els-bento-box.svg');
require('assets/common/images/help-solid-circle.svg');

interface PageHeaderProps {
  appLoading: boolean;
  breadcrumbBackTitle: string;
  eaqTopicMappedHesiExamByCourseId: {};
  isBackToPreviousAppIconShown: boolean;
  featureFlags: Array<FeatureFlag>;
  customClass?: string;
  selectedCourse: number;
  showNavigation?: boolean;
  isCWLinkHADAndEHRChecking: boolean;
  isEAQTopicMappedHesiExamLoading: boolean;
  isCWSubNavInEHRPageShow: boolean;
  hideBackToPreviousAppIcon?: boolean;
  handleBackPreviousAppIconClick?: Function;
  hadPrograms: Program[];
  appLinkOutPostBody: AppLinkOutPostBody;
  isHesiReadinessApp?: boolean;
  title: string;
  subTitle?: string;
  showChildren?: boolean;
  windowMode: WindowMode;
  courses: CourseSectionInfo[];
  setSelectedCourse: Function;
  setCourseSectionInfo: Function;
  showBentoIcon?: boolean;
  componentBelowHeader?: ReactNode;
  newHeaderShown?: boolean;
  currentAppLinkPage?: string;
  customAppLinkRedirectUrl?: string;
  studentNameDisplay?: string;
  instructorId: number;
  autoHideBento?: boolean;
  bentoBoxShown: boolean;
  supportIconShown?: boolean;
  customHeaderItem?: ReactNode;
  isProgramSolutionsEnabled: boolean;
  isEhrReportingEnabled: boolean;
}

interface PageHeaderState {
  openModal: boolean;
  showBentoModal: boolean;
  showCourseSelectionModal: boolean;
  titleHeader: string;
}

class PageHeader extends Component<PageHeaderProps, PageHeaderState> {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      showBentoModal: false,
      showCourseSelectionModal: false,
      titleHeader: isCSAppByAppName(props.breadcrumbBackTitle) ? CS_APP_TITLE : props.title
    };
  }

  renderCloseButton = (): ReactElement => (
    <button
      type="submit"
      className="u-els-debuttonize u-els-text-center u-els-padding-none u-els-display-inline-block u-els-margin-right-2x u-els-margin-right-1x@mobile c-page-header__close-btn"
      onClick={() => this.props.handleBackPreviousAppIconClick()}
    >
      <ELSIcon name="close" size="1x" customClass="u-els-color-secondary" />
    </button>
  );

  handleCancel = () => {
    const { openModal } = this.state;
    this.setState({ openModal: !openModal });
  };

  handleClickNavigationOtherReport = () => {
    trackAAFeature(ANALYTIC_FEATURES.PAGE_HEADER_ACCESS_ALL_DASHBOARD_LINK_CLICK);
    this.setState(prevStates => ({ showBentoModal: !prevStates.showBentoModal }));
  };

  handleCourseSelectionModal = () => {
    this.setState(prevStates => ({
      showCourseSelectionModal: !prevStates.showCourseSelectionModal,
      showBentoModal: false
    }));
  };

  handleOnClickOutsideModal = () => {
    this.setState({ showBentoModal: false });
  };

  handleBackPreviousAppIconClick = () => {
    if (checkPathMatch([PS_PATHS.homePage])) {
      trackAAFeature(ANALYTIC_FEATURES.PS_LANDING_PAGE_BACK_BUTTON_CLICK);
    }
    return this.props.handleBackPreviousAppIconClick();
  };

  getIsFeatureNewHeaderEnable = () => {
    const { featureFlags, newHeaderShown = false } = this.props;
    const isHADCWNewHeaderEnabled = isFeatureEnabled(featureFlags, FeatureFlagNames.HAD_CW_NEW_HEADER_ENABLE);
    if (!newHeaderShown) {
      if (sessionStorage.getItem(MASK_DATA_SESSION_KEY) === 'true') {
        const shouldShowBentoIconForDemo =
          isFeatureEnabled(featureFlags, FeatureFlagNames.PERFROMANCE_DEMO_APP_ENABLED) || isFeatureEnabled(featureFlags, FeatureFlagNames.CW_DATA_MASKING_ENABLED);
        return isHADCWNewHeaderEnabled && shouldShowBentoIconForDemo;
      }
      return isHADCWNewHeaderEnabled;
    }
    return newHeaderShown;
  };

  handleClickSupportIcon = () => {
    return null;
  };

  render() {
    const {
      isBackToPreviousAppIconShown,
      breadcrumbBackTitle,
      customClass,
      children,
      showNavigation,
      hideBackToPreviousAppIcon,
      featureFlags,
      selectedCourse,
      hadPrograms,
      isEAQTopicMappedHesiExamLoading,
      isCWLinkHADAndEHRChecking,
      isHesiReadinessApp,
      subTitle,
      windowMode,
      courses,
      componentBelowHeader,
      currentAppLinkPage,
      customAppLinkRedirectUrl,
      studentNameDisplay,
      isCWSubNavInEHRPageShow,
      appLinkOutPostBody,
      instructorId,
      bentoBoxShown,
      supportIconShown = false,
      autoHideBento = false,
      showBentoIcon = true,
      showChildren = false,
      customHeaderItem,
      isProgramSolutionsEnabled,
      isEhrReportingEnabled
    } = this.props;

    const { openModal, showBentoModal, showCourseSelectionModal, titleHeader } = this.state;
    const isShowEAQTopicReport = !isEABAppByAppName(breadcrumbBackTitle);
    const isHADPath = checkPathMatch(Object.values(HAD_PATHS));

    const pageNavigatorProps = {
      openModal,
      handleCancel: this.handleCancel,
      featureFlags,
      selectedCourse,
      hadPrograms,
      isCWLinkHADAndEHRChecking,
      isHesiReadinessApp,
      currentAppLinkPage,
      customAppLinkRedirectUrl,
      studentNameDisplay,
      isShowEAQTopicReport,
      isHADPath,
      isCWSubNavInEHRPageShow,
      appLinkOutPostBody,
      isEhrReportingEnabled,
      isCSApp: isCSAppByAppName(breadcrumbBackTitle)
    };

    const bentoBoxModal: BentoBoxModalProps = {
      hadPrograms,
      selectedCourse,
      isEAQTopicMappedHesiExamLoading,
      isCWLinkHADAndEHRChecking,
      isHesiReadinessApp,
      showCourseSelectionModal,
      visible: showBentoModal,
      handleCourseSelectionModal: this.handleCourseSelectionModal,
      onClickOutSide: this.handleOnClickOutsideModal,
      courses,
      instructorId,
      autoHideBento,
      isProgramSolutionsEnabled
    };

    const isFeatureNewHeaderEnable = this.getIsFeatureNewHeaderEnable();

    if (this.props.appLoading) {
      return <ELSPageLoader />;
    }

    const backAppIconShown = isBackToPreviousAppIconShown && !hideBackToPreviousAppIcon && isBreadcrumbBackTitleShown(breadcrumbBackTitle);
    const bentoIconShown = autoHideBento ? bentoBoxShown : showBentoIcon;

    return (
      <>
        {isFeatureNewHeaderEnable && !windowMode.mobile && titleHeader ? (
          <>
            <nav className={classNames('c-page-header c-page-header--border-none u-els-padding-none o-els-flex-layout--wrap', customClass)}>
              {bentoIconShown && <BentoBoxModal {...bentoBoxModal} />}
              <div className="u-max-width-1400 u-els-padding-left-1x1o2 u-els-padding-right-1x1o2 c-page-header__content">
                <div className="c-page-header__content-detail">
                  <svg className="c-page-header__content-detail-logo">
                    <use xlinkHref="#NonSolusLogo" />
                  </svg>
                  <div>
                    <h3>{titleHeader}</h3>
                    {subTitle && <h5>{subTitle}</h5>}
                    {backAppIconShown && (
                      <div
                        role="button"
                        tabIndex={0}
                        className="u-els-color-secondary u-els-margin-top-1o4 c-page-header__content-detail-navigation"
                        onClick={this.handleBackPreviousAppIconClick}
                        onKeyDown={evt => onKeyDownHandler(evt, this.handleBackPreviousAppIconClick)}
                      >
                        <ELSIcon name="chevron-left" size="1x" />
                        <span className="u-els-font-size-base u-els-margin-left-1o2 u-els-margin-top-1o4 ">{`Back to ${breadcrumbBackTitle}`}</span>
                      </div>
                    )}
                  </div>

                  {!!customHeaderItem && <div className="c-page-header__content-detail-navigation">{customHeaderItem}</div>}

                  {bentoIconShown && (
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={this.handleClickNavigationOtherReport}
                      onKeyDown={evt => onKeyDownHandler(evt, this.handleClickNavigationOtherReport)}
                      className={classNames('c-page-header__content-detail-navigation u-els-color-secondary', {
                        'u-els-margin-left-2x': !!customHeaderItem,
                        auto: !customHeaderItem
                      })}
                    >
                      <svg className="o-els-icon-svg o-els-icon-svg--2x">
                        <use xlinkHref="#els-bento-box" />
                      </svg>
                      <div className="u-els-margin-left-3o4">
                        Access All
                        <br />
                        Dashboards
                      </div>
                    </div>
                  )}
                  {supportIconShown && (
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={this.handleClickSupportIcon}
                      onKeyDown={evt => onKeyDownHandler(evt, this.handleClickSupportIcon)}
                      className="u-els-color-secondary u-els-margin-left-1x c-page-header__content-detail-navigation"
                    >
                      <svg className="o-els-icon-svg o-els-icon-svg--2x">
                        <use xlinkHref="#help-solid-circle" />
                      </svg>
                      <div className="u-els-margin-left-1o2">Support</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="c-page-header__content-line" />
              {showChildren && (
                <div className="c-page-header__content-line">
                  <div className="u-max-width-1400 c-page-header__content-children">{children}</div>
                </div>
              )}
            </nav>
            {!!componentBelowHeader && <div className="c-page-header__content-line">{componentBelowHeader}</div>}
          </>
        ) : (
          <nav className={classNames('u-els-padding c-page-header', customClass)}>
            {backAppIconShown && this.renderCloseButton()}
            <div className="u-els-padding-right c-page-header__content">{children}</div>
            {showNavigation && (
              <span role="button" tabIndex={0} onClick={this.handleCancel} onKeyDown={evt => onKeyDownHandler(evt, this.handleCancel)}>
                <ELSIcon customClass={classNames({ 'u-els-color-secondary': isHADPath }, 'c-page-navigator__icon')} name="menu" size="1x1o2" />
              </span>
            )}
          </nav>
        )}
        {showNavigation && <PageNavigator {...pageNavigatorProps} />}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSelectedCourse: courseId => dispatch(appActions.setSelectedCourse(courseId)),
  setCourseSectionInfo: courseSectionInfo => dispatch(courseActions.setCourseSectionInfo(courseSectionInfo))
});

const mapStateToProps = state => {
  return {
    eaqTopicMappedHesiExamByCourseId: appSelectors.getEAQTopicMappedHesiExamByCourseId(state),
    featureFlags: appSelectors.getFeatureFlags(state),
    selectedCourse: appSelectors.getSelectedCourse(state),
    appLoading: appSelectors.getAppLoading(state),
    breadcrumbBackTitle: appSelectors.getBreadcrumbBackTitle(state),
    isBackToPreviousAppIconShown: appSelectors.getIsBackToPreviousAppIconShown(state),
    isCWLinkHADAndEHRChecking: appSelectors.getIsCWLinkHADAndEHRChecking(state),
    isEAQTopicMappedHesiExamLoading: appSelectors.getIsEAQTopicMappedHesiExamLoading(state),
    courses: appSelectors.getCourses(state),
    isCWSubNavInEHRPageShow: appSelectors.getIsCWSubNavInEHRPageShow(state),
    appLinkOutPostBody: appSelectors.getAppLinkOutPostBody(state),
    hadPrograms: hadSelectors.getPrograms(state),
    instructorId: shSelectors.getInstructorId(state),
    bentoBoxShown: appSelectors.getBentoBoxShown(state),
    isProgramSolutionsEnabled: appSelectors.getIsProgramSolutionsEnabled(state),
    isEhrReportingEnabled: appSelectors.getIsEhrReportingEnabled(state)
  };
};

export { PageHeader as PageHeaderBase };
export default connect(mapStateToProps, mapDispatchToProps)(withResizeScreen(withAppLinkingBackToPreviousApp(PageHeader)));
